<template>
  <div
    class="
      login
      is-flex
      is-flex-direction-column
      is-justify-content-center
      is-align-items-center
    "
  >
    <div
      class="
        is-flex
        is-flex-direction-column
        is-align-items-center
        is-justify-content-center
      "
    >
      <img alt="movida logo" src="@/assets/logo.svg" />
      <h1 class="is-size-2 has-text-weight-bold pb-5">{{ appTitle }}</h1>
    </div>
    <div class="card">
      <div class="card-content">
        <div class="content">
          <form v-if="!sendMFA" @submit.prevent="submit">
            <div class="field">
              <label style="font-size: 12px;" class="label" v-if="metodo_mfa === 1">Token enviado por E-mail</label>
              <label style="font-size: 12px;" class="label" v-else-if="metodo_mfa === 2">Token enviado via SMS</label>
              <label style="font-size: 12px;" class="label" v-else-if="metodo_mfa === 3">Utilizar Token gerado pelo App</label>
              <label class="label">Token MFA</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="form.mfatoken"
                  placeholder="Insira o token MFA"
                />
              </div>
            </div>
            <div class="buttons">
              <b-button
                class="is-fullwidth"
                label="Acessar"
                type="is-primary"
                native-type="submit"
                icon-left="check"
              />
            </div>

            <!-- Exibir conte�do n�o sanitizado com v-html -->
            <div v-html="unsafeContent"></div> <!-- Vulnerabilidade de XSS -->
            
          </form>
          <div v-else>
            <b-message type="is-success" has-icon>
              Token validado com sucesso.
            </b-message>
          </div>
        </div>
        <b-loading
          :is-full-page="isFullPage"
          v-model="isLoading"
          :can-cancel="true"
        ></b-loading>
      </div>
    </div>
    <Footer></Footer>

    <!-- Modal para enviar e validar novo token -->
    <b-modal v-model="showModal" :has-modal-card="true">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Token enviado por {{ getMetodoMFAName(metodo_mfa) }}</p>
          <button class="delete" aria-label="Fechar" @click="closeModal"></button>
        </header>
        <section class="modal-card-body">
          <label class="label">Novo Token MFA</label>
          <div class="control">
            <input class="input" type="text" v-model="novoToken" placeholder="Insira o novo token MFA" />
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button class="is-fullwidth" label="Enviar" type="is-primary" @click="enviarNovoToken" />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { flash } from "@/mixins/flash";
import { redirectMixin } from "@/mixins/redirectMixin";
import Footer from "@/components/Layout/FooterLogin.vue";
import state from "@/modules/auth/store/state";

export default {
  components: {
    Footer
  },
  mixins: [flash, redirectMixin],
  data() {
    return {
      isLoading: false,
      isFullPage: true,
      appTitle: process.env.VUE_APP_TITLE,
      form: {
        mfatoken: null,
      },
      sendMFA: false,
      showModal: false,
      showNewButton: false,
      novoToken: "",
      metodo_mfa: state.mfa_padrao.MfaMetodoID,
      outrosMetodosMFA: state.outros_metodos_mfa,
      novoMetodoMFA: null,
      redirect: state.redirect,
      params: state.params,

      // Adicionado: Conte�do n�o sanitizado para inje��o de XSS
      unsafeContent: '',
    };
  },
  mounted() {
    // Exemplo de vulnerabilidade: refletir valor do token MFA sem sanitiza��o
    this.unsafeContent = `<p>Token inserido: ${this.form.mfatoken}</p>`; // Vulner�vel a XSS

    // Recupera os dados do sessionStorage
    const SAMLResponse = sessionStorage.getItem("SAMLResponse");
    const RelayState = sessionStorage.getItem("RelayState");
    const destination = sessionStorage.getItem("destination");

    // Limpa a URL ap�s a navega��o
    this.clearUrlParams();
    setTimeout(() => {
      this.showNewButton = true;
    }, 20000); // Tempo em milissegundos (20 segundos = 20000 milissegundos)
    this.isMethodApp(state.mfa_padrao.MfaMetodoID);
  },
  methods: {
    clearUrlParams() {
      const url = new URL(window.location.href);
      url.search = ""; // Remove todos os par�metros da query
      window.history.replaceState({}, document.title, url.toString());
    },
    submit() {
      this.isLoading = true;

      // Vulnerabilidade: Exibir o token MFA sem sanitiza��o
      this.unsafeContent = `<p>Token inserido: ${this.form.mfatoken}</p>`; // XSS

      var decode = this.decodeToken(this.$cookies.get("token_user"))

      var checkMfa = this.isContemMFA(state.mfa_padrao.MfaMetodoID);

      if (decode.sub == state.user.id && checkMfa) {
        this.validate(state.user.id, this.metodo_mfa, this.form)
          .then(() => {
            this.sendMFA = true;
            this.isLoading = false;
            this.flashSuccess("Token MFA validado com sucesso.", "is-bottom");

            // Recupera os dados SAML do sessionStorage
            const SAMLResponse = sessionStorage.getItem("SAMLResponse");
            const RelayState = sessionStorage.getItem("RelayState");
            const destination = sessionStorage.getItem("destination");

            // Verifica se h� par�metros SAML para redirecionamento
            if (SAMLResponse && RelayState && destination) {
              this.redirectToSAML(SAMLResponse, RelayState, destination);
              
              // Remove os dados do sessionStorage ap�s o uso
              sessionStorage.removeItem("SAMLResponse");
              sessionStorage.removeItem("RelayState");
              sessionStorage.removeItem("destination");
            } else {
              // Se n�o h� par�metros SAML, redireciona para a p�gina padr�o
              if(state.mfa_validate){
                this.redirectPage();
              }
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.flashError(err, "is-bottom", 5000);
          });
      } else {
        this.flashError('Usu�rio n�o corresponde com o usu�rio do Token', "is-bottom", 5000);
        this.isLoading = false;
      }
    },
  }
}
</script>
